import { graphql, useStaticQuery } from 'gatsby';

export const useValuesAndMissionData = () => {
  const data = useStaticQuery(graphql`
    query {
      valuesAndMissionDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
      }
    }
  `);

  return data.valuesAndMissionDataJson;
};
