import React from 'react';

// External Components
import { Section, Heading, Box } from '@thepuzzlers/pieces';

// Local Components
import {
  CircleSwirl,
  ColumnedParagraph,
  HorizontalDivider,
  SinusoidalLine
} from 'components';

export const DescriptionList = ({ data: { list } }) => {
  return (
    <Section id="values-and-mission__description-list">
      {list.map((data, index) => (
        <React.Fragment key={index}>
          <Divider />
          <ListItem data={data} isRight={index % 2 !== 0}>
            {index === 1 && <SecondListDecoration />}
            {index === 3 && <FourthListDecoration />}
          </ListItem>
        </React.Fragment>
      ))}
    </Section>
  );
};

const ListItem = ({
  data: { title, first_text, second_text },
  isRight,
  children = null
}) => {
  return (
    <Box
      className="__list-item"
      sx={{
        gridColumn: isRight
          ? ['1/13', null, '6/ span 18', null, '4/25', '5/ span 18']
          : ['1/13', null, '3/ span 18', null, '2/ span 21', '3/ span 18'],
        position: 'relative'
      }}>
      <Heading
        as="h2"
        sx={{
          fontFamily: 'primary.bold',
          lineHeight: 1.25,
          fontSize: ['4.8rem', null, '6.4rem', null, '7.2rem', '7.2rem'],
          hyphens: 'auto'
        }}>
        {title}
      </Heading>
      <ColumnedParagraph firstText={first_text} secondText={second_text} />
      {/* This Children meant to be used to pace in the decoration */}
      {children}
    </Box>
  );
};

const Divider = () => {
  return (
    <HorizontalDivider
      sx={{
        gridColumn: ['1/13', null, '2/ span 22', null, '1/25', '1/25'],
        ':first-of-type': {
          display: 'none'
        },
        my: ['12rem', null, '14rem', null, '14rem', '14rem']
      }}
    />
  );
};

const SecondListDecoration = () => {
  return (
    <SinusoidalLine
      sx={{
        position: 'absolute',
        width: ['12.5rem', null, '15.8rem', null, '15.8rem', '15.8rem'],
        top: ['8.7rem', null, '21.8rem', null, '14.5rem', '12.9rem'],
        left: ['23.3rem', null, '-21.3rem', null, '-23rem', '-29.6rem']
      }}
    />
  );
};

const FourthListDecoration = () => {
  return (
    <CircleSwirl
      sx={{
        position: 'absolute',
        width: ['6.9rem', null, '9.5rem', null, '11.8rem', '11.8rem'],
        bottom: ['-10.5rem', null, '-12rem', null, '-6.7rem', '-9.8rem'],
        right: ['12.5rem', null, '-4.7rem', null, '4.2rem', '-2.6rem']
      }}
    />
  );
};
