import React from 'react';

// Local Components
import { BigDescriptionBlock } from 'components';

// External Components
import { Section } from '@thepuzzlers/pieces';

// Local Components
export const About = ({ data: { description } }) => {
  return (
    <Section id="values-and-mission__about">
      <BigDescriptionBlock description={description} />
    </Section>
  );
};
