import React from 'react';

// External
import { Section, Heading, Box } from '@thepuzzlers/pieces';

// Local Components
import { AspectRatioImageContainer, GridSpacer } from 'components';

export const Header = ({ data: { image, headline, title } }) => {
  return (
    <Section as="header" id="values-and-mission__header">
      <TopSpacer />
      <TitleAndHeadline title={title} headline={headline} />
      <Image image={image} />
    </Section>
  );
};

const TitleAndHeadline = ({ title, headline }) => {
  return (
    <Box
      className="__title-and-headline"
      sx={{
        gridColumn: [
          '1/13',
          null,
          '2/ span 12',
          null,
          '2/ span 15',
          '3/ span 12'
        ],
        mt: ['15.6rem', null, 0, null, 0, '19.3rem'],
        gridRow: [null, null, null, null, 2, 2]
      }}>
      <Heading
        className="__title"
        as="h1"
        sx={{
          fontFamily: 'body.normal',
          lineHeight: 1,
          letterSpacing: '0.05em',
          fontSize: ['1.4rem', null, '1.6rem', null, '1.8rem', '1.8rem'],
          textTransform: 'uppercase'
        }}>
        {title}
      </Heading>
      <Heading
        as="h2"
        className="__headline"
        sx={{
          fontFamily: 'primary.bold',
          lineHeight: 1.25,
          fontSize: ['4.8rem', null, '5.6rem', null, '6.4rem', '7.2rem'],
          mt: ['1.8rem', null, '1.8rem', null, '2rem', '3rem']
        }}>
        {headline}
      </Heading>
    </Box>
  );
};

const Image = ({ image: { image, alt } }) => {
  return (
    <AspectRatioImageContainer
      src={image}
      alt={alt}
      aspectRatios={1 / 1.41}
      sx={{
        gridColumn: ['1/13', null, 14, null, 14, 15],
        gridRow: [null, null, null, null, 2, 2],
        justifySelf: ['center', null, 'start', null, 'start', 'start'],
        width: ['34.5rem', null, '33.2rem', null, '43.9rem', '52.2rem'],
        mt: ['1rem', null, '9.7rem', null, '8.7rem', 0]
      }}
    />
  );
};

const TopSpacer = () => {
  return (
    <GridSpacer
      height={[null, null, '14.9rem', null, '21.9rem', '6.4rem']}
      sx={{
        display: ['none', null, 'block', null, 'block', 'block']
      }}
    />
  );
};
